import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { ToastPopoverService } from '../../components/toast-popovers/toast-popovers.service';

export interface CraftError extends ErrorConstructor {
  cause: HttpErrorResponse;
}

export const craftErrorHandlingInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMsg = '';
      if (error.status === 0) {
        console.debug('This is client side error');
      } else {
        console.debug('This is server side error');
      }
      errorMsg = error.error?.message || error.message || error;
      console.error(error);
      return throwError(() => new Error(errorMsg, { cause: error }));
    }),
  );
};
