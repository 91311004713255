@defer {
  <app-loading-bar></app-loading-bar>
}
<app-skip-content></app-skip-content>
<header role="navigation" aria-label="Hauptnavigation" app-header></header>
<div id="content" tabindex="-1"></div>
<router-outlet></router-outlet>
<footer role="contentinfo" aria-label="Footer" app-footer></footer>
@defer {
  <app-scroll-top></app-scroll-top>
}
@if (outletActivated()) {
  <div
    id="outlet-bg"
    class="layout-grid fixed inset-0 z-50 bg-black/90 text-white"
  >
    <div class="header pt-3 lg:pt-6" aria-hidden>
      <app-logo class="block h-12 w-auto lg:h-20"></app-logo>
    </div>
    <a
      [routerLink]="['', { outlets: { aside: null } }]"
      queryParamsHandling="preserve"
      class="fixed inset-0"
    >
      <span class="sr-only">close</span>
    </a>
  </div>
}
<aside
  id="outlet-aside"
  [ngClass]="[outletActivated() ? 'translate-x-0' : 'translate-x-full']"
  class="ease-smooth fixed right-0 top-0 z-50 h-dvh w-full overflow-auto overscroll-contain transition-transform duration-700 lg:max-w-[calc(100vw-18rem)] 2xl:max-w-6xl 2xl:pl-0"
  [cdkTrapFocus]="outletActivated()"
  [cdkTrapFocusAutoCapture]="outletActivated()"
>
  <noscript
    class="px-dynamic-xs sticky top-0 z-10 block h-auto w-full bg-red-500 py-6 text-white lg:py-8"
    >You need Javascript enabled to view this page correctly.</noscript
  >
  <router-outlet
    #outlet="outlet"
    (deactivate)="setOutletState(false, true)"
    (activate)="setOutletState(true)"
    name="aside"
  ></router-outlet>
</aside>
<app-toast-popovers></app-toast-popovers>
