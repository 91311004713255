import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LogoComponent } from '../logo/logo.component';
import { MainNavigationComponent } from '../main-navigation/main-navigation.component';

@Component({
  selector: 'header[app-header]',
  standalone: true,
  imports: [RouterLink, LogoComponent, MainNavigationComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  host: {
    class: 'header flex gap-4 lg:gap-8 pt-3 lg:pt-6 sticky top-0 z-40',
  },
})
export class HeaderComponent {}
