import { CdkTrapFocus } from '@angular/cdk/a11y';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  effect,
  HostBinding,
  HostListener,
  inject,
  input,
  PLATFORM_ID,
  Renderer2,
  signal,
  untracked,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { A11yService } from '../../utils/services/a11y.service';
import { NavigationService } from '../../utils/services/navigation.service';
import { MainNavItemComponent } from './main-nav-item/main-nav-item.component';
import {
  trigger,
  transition,
  style,
  query,
  animate,
  animateChild,
  group,
} from '@angular/animations';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { SafePipe } from '@ynmstudio/utils/pipes/safe';
import { RelativeUrlPipe } from '../../utils/pipes/relative-url.pipe';
import { TooltipDirective } from '../../utils/directives/tooltip.directive';
import { scroll } from 'motion';
import { LanguageNameI18nPipe } from '../../utils/pipes/language-name-i18n.pipe';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-main-navigation',
  standalone: true,
  templateUrl: './main-navigation.component.html',
  styleUrl: './main-navigation.component.scss',
  host: {
    class: 'flex-auto flex justify-end',
  },
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        query('@openClose', animateChild(), { optional: true }),
        animate(
          '0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
          style({ transform: 'translateX(0)' }),
        ),
      ]),
      transition(':leave', [
        animate(
          '0.7s cubic-bezier(0.645, 0.045, 0.355, 1)',
          style({ transform: 'translateX(100%)' }),
        ),
      ]),
    ]),
  ],
  imports: [
    CommonModule,
    CdkTrapFocus,
    RouterLink,
    RouterLinkActive,
    MainNavItemComponent,
    TranslocoModule,
    SafePipe,
    RelativeUrlPipe,
    TooltipDirective,
    LanguageNameI18nPipe,
  ],
})
export class MainNavigationComponent implements AfterViewInit {
  a11yService = inject(A11yService);

  navigationService = inject(NavigationService);
  #renderer = inject(Renderer2);
  #platformId = inject(PLATFORM_ID);
  #transloco = inject(TranslocoService);

  langChanges = toSignal(this.#transloco.langChanges$);
  currentLang = computed(() => this.langChanges() ?? 'de');

  delta = signal(150);
  current = signal<number>(0);
  lastCurrent = signal(0);
  scrolledDownDirection = signal(false);
  #scrollDirectionChangeEffect = effect(
    () => {
      const scrolledDown = this.scrolledDownDirection();
      untracked(() => this.lastCurrent.set(this.current()));
    },
    { allowSignalWrites: true },
  );

  scrolled = computed(() => this.current() > this.delta());
  #scrolledEffect = effect(() => {
    this.addRemoveClass(this.scrolled(), 'scrolled');
  });

  hideNav = computed(
    () =>
      Math.abs(this.current() - this.lastCurrent()) > this.delta() &&
      this.scrolledDownDirection(),
  );
  #hideNavEffect = effect(() => {
    this.addRemoveClass(this.hideNav(), 'hide-nav');
  });

  #menuCloseTimeout!: NodeJS.Timeout;
  #menuShowTimeout!: NodeJS.Timeout;

  toggleNavigation() {
    clearTimeout(this.#menuCloseTimeout);
    clearTimeout(this.#menuShowTimeout);

    this.navigationService.toggleMainNavigation();
  }

  @HostListener('window:keydown.escape', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    this.navigationService.toggleMainNavigation(false);
  }

  preventClosing() {
    clearTimeout(this.#menuCloseTimeout);
  }
  closeMenuItem() {
    clearTimeout(this.#menuShowTimeout);
    this.#menuCloseTimeout = setTimeout(() => {
      this.navigationService.toggleMainNavigation(false);
    }, 700);
  }

  toggleFontSize() {
    this.a11yService.toggleFontSize();
  }
  toggleHighContrast() {
    this.a11yService.toggleHighContrast();
  }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.#platformId)) return;
    scroll(({ y }) => {
      this.current.set(y.current);
      this.scrolledDownDirection.set(y.velocity >= 0);
    });
  }

  addRemoveClass(state: boolean, className: string) {
    state
      ? this.#renderer.addClass(
          this.#renderer.selectRootElement('html', true),
          className,
        )
      : this.#renderer.removeClass(
          this.#renderer.selectRootElement('html', true),
          className,
        );
  }

  // this.ngZone.runOutsideAngular(() => {
  //   merge(
  //     fromEvent(window, "scroll", { passive: true }).pipe(
  //       throttleTime(100),
  //       map(() => "throttle")
  //     ),
  //     fromEvent(window, "scroll", { passive: true }).pipe(
  //       debounceTime(200),
  //       map(() => "debounce")
  //     )
  //   )
  //     .pipe(untilDestroyed(this))
  //     .subscribe(() => this.hasScrolled());

  //   merge(
  //     fromEvent(window, "resize").pipe(
  //       throttleTime(100),
  //       map(() => "throttle")
  //     ),
  //     fromEvent(window, "resize").pipe(
  //       debounceTime(200),
  //       map(() => "debounce")
  //     )
  //   )
  //     .pipe(untilDestroyed(this))
  //     .subscribe(() => this.hasResized());
  // });

  // hasResized() {
  //   this.addRemoveClass(false, "scrolled");
  //   this.getDimensions();
  // }
  // private getDimensions() {
  //   if (!this.checkPlatform.isPlatformBrowser) return;
  //   const vh = window.innerHeight * 0.01;
  //   // Then we set the value in the --vh custom property to the root of the document
  //   this.#renderer.setAttribute(this.document.body, "style", `--vh: ${vh}px`);
  // }

  // hasScrolled() {
  //   if (!this.checkPlatform.isPlatformBrowser) return;
  //   let st =
  //     window.scrollY ||
  //     window.pageYOffset ||
  //     this.document.body.scrollTop +
  //     ((this.document.documentElement &&
  //       this.document.documentElement.scrollTop) ||
  //       0);

  //   if (st < this.delta) {
  //     // this.menuAnimationState = "nav-down";
  //     this.addRemoveClass(false, "scrolled");
  //     this.#renderer.addClass(
  //       this.#renderer.selectRootElement("html", true),
  //       "top"
  //     );
  //     return;
  //   }

  //   // Make sure they scroll more than delta
  //   if (Math.abs(this.lastScrollTop - st) <= this.delta) return;

  //   // If they scrolled down and are past the navbar, add class .nav-up.
  //   // This is necessary so you never see what is "behind" the navbar.
  //   if (st > this.lastScrollTop && st > this.delta) {
  //     // Scroll Down
  //     this.addRemoveClass(true, "scrolled");
  //   } else {
  //     // Scroll Up
  //     if (
  //       st + window.innerHeight <
  //       this.document.documentElement.offsetHeight
  //     ) {
  //       this.addRemoveClass(false, "scrolled");
  //     }
  //   }
  //   this.lastScrollTop = st;

  //   this.#renderer.removeClass(
  //     this.#renderer.selectRootElement("html", true),
  //     "top"
  //   );
  // }
}
