import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'languageNameI18n',
  standalone: true,
})
export class LanguageNameI18nPipe implements PipeTransform {
  transform(iso: string | undefined | null, lang: string): string | undefined {
    if (!iso) return iso ?? undefined;
    const translateRegionName = new Intl.DisplayNames([lang], {
      type: 'language',
    });
    return translateRegionName.of(iso);
  }
}
