import { inject } from '@angular/core';
import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { GetHomepageGQL, GetHomepageQuery } from 'generated/types.graphql-gen';
import { EMPTY, of, switchMap } from 'rxjs';
import {
  extractActiveLang,
  extractLocalized,
} from '../../utils/services/navigation.service';

export const homepageResolver: ResolveFn<GetHomepageQuery | never> = (
  route: ActivatedRouteSnapshot,
) => {
  console.log(route.data['site']);
  return inject(GetHomepageGQL)
    .fetch({
      site: route.data['site'],
    })
    .pipe(
      extractActiveLang(),
      extractLocalized(),
      switchMap((result) => (result.data.entry ? of(result.data) : EMPTY)),
    );
};
