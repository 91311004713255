import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import posthog from 'posthog-js';

posthog.init('phc_1tYlF0jklC5m2g2d1r34MsHUEGLYgpAfd7ey0ErW3Jq', {
  api_host: 'https://eu.posthog.com',
  persistence: 'memory',
  capture_pageview: false,
  capture_pageleave: true,
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
