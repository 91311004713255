<ng-container *transloco="let t">
  <div
    [cdkTrapFocus]="navigationService.showMainNavigation()"
    [ngClass]="{ 'show-nav': navigationService.showMainNavigation() }"
    (mouseover)="preventClosing()"
  >
    <div
      id="a11y-nav"
      [ngClass]="[
        navigationService.showMainNavigation()
          ? 'text-white'
          : 'md:text-inherit',
      ]"
      class="relative z-40 flex flex-wrap items-center justify-end gap-x-6 gap-y-2"
    >
      <nav
        [ngClass]="[
          navigationService.showMainNavigation()
            ? 'pointer-events-auto translate-y-0 delay-300'
            : 'pointer-events-none translate-y-full duration-75 md:pointer-events-auto',
          !navigationService.showMainNavigation() && hideNav()
            ? 'md:-translate-y-14 md:delay-500'
            : 'md:translate-y-0',
        ]"
        class="from-accent/100 via-accent/100 to-accent/0 ease-smooth fixed inset-x-0 bottom-0 flex items-center justify-center gap-4 bg-gradient-to-t py-4 transition-transform md:static md:bg-none md:py-0 md:delay-0 md:duration-500"
      >
        <ul id="language-switcher">
          @for (lang of navigationService.langSwitch(); track $index) {
            <li>
              <a
                [routerLink]="lang.url | relativeUrl"
                [appTooltip]="
                  t('nav.switchLanguage', {
                    lang: (lang.language | languageNameI18n: currentLang()),
                  })
                "
                routerLinkActive="active"
                [routerLinkActiveOptions]="{
                  matrixParams: 'ignored',
                  queryParams: 'ignored',
                  paths: 'exact',
                  fragment: 'ignored',
                }"
                [queryParamsHandling]="'merge'"
                [state]="{ skipCloseMainNavigation: true }"
              >
                <span [innerText]="lang.language | uppercase"></span>
              </a>
            </li>
          }
        </ul>
        <ul class="flex items-center gap-6 md:gap-3">
          <li>
            <button
              [ngClass]="{ 'text-red-500': a11yService.highContrast() }"
              (click)="toggleHighContrast()"
              class="rounded-full align-middle focus-visible:ring-4"
              [appTooltip]="
                a11yService.highContrast()
                  ? t('nav.highContrastOff')
                  : t('nav.highContrastOn')
              "
              appTooltipShowDelay="700"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-auto overflow-visible"
              >
                <circle
                  cx="15"
                  cy="15"
                  r="14.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  vector-effect="non-scaling-stroke"
                />
                <path d="M 15 0 a 1 1 90 0 0 0 30" fill="currentColor" />
              </svg>

              <span class="sr-only">Contrast</span>
            </button>
          </li>
          <li>
            <button
              [ngClass]="{ 'text-red-500': a11yService.fontSize() > 1 }"
              (click)="toggleFontSize()"
              class="rounded-full align-middle focus-visible:ring-4"
              [appTooltip]="t('nav.changeFontSize')"
              appTooltipShowDelay="700"
            >
              <svg
                width="30"
                height="30"
                class="group/font h-8 w-auto overflow-visible"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
              >
                <circle
                  cx="15"
                  cy="15"
                  r="14.5"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  vector-effect="non-scaling-stroke"
                />

                <g
                  id="Icons"
                  class="a11yMedium:scale-100 a11yLarge:scale-125 scale-90"
                  transform-origin="50% 50%"
                >
                  <path
                    stroke-width="0"
                    fill="currentColor"
                    d="M11.61,10h1.56l3.57,10h-1.42l-1.04-3.04h-3.84l-1.05,3.04h-1.39l3.61-10ZM13.87,15.79l-1.51-4.39-1.52,4.39h3.03Z"
                  />
                  <polygon
                    stroke-width="0"
                    fill="currentColor"
                    points="22 14.51 19.65 14.51 19.65 12.16 18.65 12.16 18.65 14.51 16.3 14.51 16.3 15.51 18.65 15.51 18.65 17.87 19.65 17.87 19.65 15.51 22 15.51 22 14.51"
                  />
                </g>
              </svg>
              <span
                class="sr-only"
                [innerHtml]="t('nav.changeFontSize')"
              ></span>
            </button>
          </li>
          @for (iconNode of navigationService.iconNavigation(); track $index) {
            <li>
              <a
                [routerLink]="iconNode?.url | relativeUrl"
                [target]="iconNode?.newWindow ? '_blank' : '_self'"
                routerLinkActive="text-red-500"
                class="rounded-full align-middle focus-visible:ring-4"
              >
                @if ($any(iconNode)?.icon?.inline?.length) {
                  <div
                    class="h-8"
                    [appTooltip]="iconNode?.title ?? ''"
                    [innerHTML]="$any(iconNode).icon?.inline | safe"
                    appTooltipShowDelay="700"
                  ></div>
                  <span class="sr-only" [innerText]="iconNode?.title"></span>
                } @else {
                  <span [innerText]="iconNode?.title"></span>
                }
              </a>
            </li>
          }
        </ul>
      </nav>
      <button class="flex items-center gap-3" (click)="toggleNavigation()">
        <div class="grid text-right md:min-w-24">
          <div
            [attr.aria-hidden]="navigationService.showMainNavigation()"
            [ngClass]="[
              navigationService.showMainNavigation()
                ? 'opacity-0'
                : 'opacity-100',
            ]"
            class="col-start-1 row-start-1"
          >
            {{ t("nav.menu") }}
          </div>
          <div
            [attr.aria-hidden]="!navigationService.showMainNavigation()"
            [ngClass]="[
              !navigationService.showMainNavigation()
                ? 'opacity-0'
                : 'opacity-100',
            ]"
            class="col-start-1 row-start-1"
          >
            {{ t("buttons.close") | titlecase }}
          </div>
        </div>
        <svg
          class="h-auto w-8 overflow-visible lg:w-auto"
          width="40"
          height="20"
          viewBox="0 0 40 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          vector-effect="non-scaling-stroke"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            class="ease-smooth transition-[d] duration-300"
            [attr.d]="
              navigationService.showMainNavigation()
                ? 'M 0 0 L 20 10 L 0 20 M 40 0 L 20 10 L 40 20 M 20 1 L 20 1 L 20 1 M 20 19 L 20 19 L 20 19'
                : 'M 0 10 L 20 10 L 0 10 M 40 10 L 20 10 L 40 10 M 0 1 L 20 1 L 40 1 M 0 19 L 20 19 L 40 19'
            "
          />
        </svg>
      </button>
    </div>
    @if (navigationService.showMainNavigation()) {
      <nav
        @slideInOut
        (mouseleave)="closeMenuItem()"
        class="bg-accent layout-grid py-dynamic fixed right-0 top-0 z-0 h-dvh w-full max-w-5xl overflow-y-auto overscroll-contain text-white shadow-2xl md:w-11/12"
      >
        <ul id="main-nav">
          @for (mainNode of navigationService.mainNavigation(); track $index) {
            <li>
              <a
                main-nav-item
                [navRouterLink]="mainNode?.url | relativeUrl"
                [target]="mainNode?.newWindow ? '_blank' : '_self'"
                #rla="routerLinkActive"
                navRouterLinkActive="active"
              >
                <span [innerText]="mainNode?.title"></span>
              </a>
            </li>
          }
        </ul>
        <div id="sub-nav">
          @for (
            nodeGroup of navigationService.mainSubNavigation();
            track $index
          ) {
            <ul>
              @if (
                nodeGroup?.type !== "verbb\\navigation\\nodetypes\\PassiveType"
              ) {
                <li>
                  <a
                    [routerLink]="nodeGroup?.url | relativeUrl"
                    [target]="nodeGroup?.newWindow ? '_blank' : '_self'"
                    #rla="routerLinkActive"
                    routerLinkActive="active"
                    [innerText]="nodeGroup?.title"
                  >
                  </a>
                </li>
              }
              @for (subItem of nodeGroup?.children; track $index) {
                <li>
                  <a
                    [routerLink]="subItem?.url | relativeUrl"
                    [target]="subItem?.newWindow ? '_blank' : '_self'"
                    #rla="routerLinkActive"
                    routerLinkActive="active"
                    [innerText]="subItem?.title"
                  >
                  </a>
                </li>
              }
            </ul>
          }
        </div>
      </nav>
    }
  </div>
</ng-container>
