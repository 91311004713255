<ng-container *transloco="let t">
  <a
    id="first-element"
    [routerLink]="[]"
    fragment="content"
    [innerText]="t('links.skip.to-content.name')"
  >
  </a>
  <a
    [routerLink]="[]"
    fragment="footer"
    [innerText]="t('links.skip.to-footer.name')"
  >
  </a>
</ng-container>
