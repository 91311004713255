import { inject, PLATFORM_ID } from '@angular/core';
import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { GetPageGQL, GetPageQuery } from 'generated/types.graphql-gen';
import { of, switchMap } from 'rxjs';
import {
  extractActiveLang,
  extractLocalized,
} from '../../utils/services/navigation.service';
import { isPlatformServer } from '@angular/common';
import { RESPONSE } from 'projects/website/src/express.tokens';
import { IsrService } from '@rx-angular/isr/browser';

export const pageResolver: ResolveFn<GetPageQuery | boolean | never> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const platformId = inject(PLATFORM_ID);
  const isrService = inject(IsrService);
  const response = inject(RESPONSE, { optional: true });
  return inject(GetPageGQL)
    .fetch({
      uri: route.url.join('/'),
      prefixedUri: `/${route.url.join('/')}`,
      site: route.data['site'],
    })
    .pipe(
      extractActiveLang(),
      extractLocalized(),
      switchMap((result) => {
        if (result.data.entry) {
          return of(result.data);
        } else {
          isrService.addError({
            name: 'No page',
            message: 'This page does not exist',
          } as Error);

          /**
           * @description this navigateByUrl() function both handels redirects where we are using the /en prefix or the  language with no prefix (de).
           */
          // @TODO Move retour redirect to express.js server so we can set http status 301
          // @BODY requires https://github.com/nystudio107/craft-retour/issues/288 to be resolved!

          if (result.data.retourResolveRedirect?.redirectDestUrl) {
            if (isPlatformServer(platformId) && response) {
              response.status(
                result.data.retourResolveRedirect?.redirectHttpCode ?? 301,
              );
            }
            return router.navigateByUrl(
              route.parent?.url.join('/') +
                (result.data.retourResolveRedirect?.redirectDestUrl || ''),
            );
          } else {
            return router.navigateByUrl(
              `/${route.url.join('/')}?errorCode=404`,
              { skipLocationChange: true },
            );
          }
        }
      }),
    );
};
