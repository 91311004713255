import { inject } from '@angular/core';
import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { ApolloQueryResult } from '@apollo/client';
import {
  GetNavigationGQL,
  GetNavigationQuery,
} from 'generated/types.graphql-gen';
import { extractNavigation } from '../services/navigation.service';

export const navigationResolver: ResolveFn<
  ApolloQueryResult<GetNavigationQuery>
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(GetNavigationGQL)
    .fetch(
      {
        site: route.data['site'],
      },
      { fetchPolicy: 'network-only' },
    )
    .pipe(extractNavigation());
};
