import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  PLATFORM_ID,
  computed,
  inject,
  input,
} from '@angular/core';
import { Toast, ToastPopoverService } from '../toast-popovers.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  host: {
    popover: 'manual',
  },
})
export class ToastComponent implements AfterViewInit {
  toast = input.required<Toast>({ alias: 'data' });

  @HostBinding('class')
  get type() {
    return this.toast().type;
  }

  #elRef = inject(ElementRef);
  #toastService = inject(ToastPopoverService);
  #platform_id = inject(PLATFORM_ID);

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.#platform_id)) return;
    this.#elRef.nativeElement.showPopover();
    setTimeout(() => {
      this.#elRef.nativeElement.hidePopover();
      setTimeout(() => {
        this.#toastService.removeToast(this.toast().id);
      }, 500);
    }, 3500);
    setTimeout(() => {}, 500);
  }
}
