import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpBatchLink, HttpLink } from 'apollo-angular/http';
import {
  ApplicationConfig,
  inject,
  InjectionToken,
  isDevMode,
  makeStateKey,
  TransferState,
} from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';

import introspectionResult from 'generated/types.graphql-gen';
import { ToastPopoverService } from '../components/toast-popovers/toast-popovers.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

/**
 * In dev mode, the API is served from the same server as the website via proxy.conf.json
 */
const uri = environment.apiUrl;

const APOLLO_CACHE = new InjectionToken<InMemoryCache>('apollo-cache');
const STATE_KEY = makeStateKey<any>('apollo.state');

export function apolloOptionsFactory(): ApolloClientOptions<any> {
  const httpLink = inject(HttpBatchLink);
  const router = inject(Router);
  const transferState = inject(TransferState);
  const toastPopoverService = inject(ToastPopoverService);

  const isBrowser = transferState.hasKey<any>(STATE_KEY);

  const http = httpLink.create({ uri });

  const link = ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${extensions ? extensions['code'] : 'unknown'}`,
          );
          toastPopoverService.addToast('Fehler bei der Anfrage', 'error');
          // switch (extensions['code']) {
          //   case "invalid-jwt":
          //     // const oldHeaders = operation.getContext().headers;
          //     // operation.setContext({
          //     //   headers: {
          //     //     ...oldHeaders,
          //     //     authorization: getNewToken(),
          //     //   },
          //     // });
          //     // // retry the request, returning the new observable
          //     // return forward(operation);
          //     break;
          //   default:
          //     break;
          // }
        });
      if (networkError) {
        toastPopoverService.addToast('Verbindungsfehler...', 'error');
        router.navigateByUrl(`/?errorCode=${500}`, {
          skipLocationChange: true,
        });
        console.error(`[Network error]: ${networkError}`);
        console.error(networkError);
        // alert("New network error. Check console");
      }
    }),
    http,
  ]);

  const cache = new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
    typePolicies: {
      EntryInterface: {
        keyFields: ['id', 'siteId'],
      },
    },
  });

  if (isBrowser) {
    const state = transferState.get<any>(STATE_KEY, null);
    cache.restore(state);
  } else {
    transferState.onSerialize(STATE_KEY, () => {
      return cache.extract();
    });
    // Reset cache after extraction to avoid sharing between requests
    cache.reset();
  }

  return {
    link: link,
    cache,
  };
}

export function provideGraphQL(): ApplicationConfig['providers'] {
  return [
    Apollo,
    {
      provide: APOLLO_OPTIONS,
      useFactory: apolloOptionsFactory,
    },
  ];
}
