import { Component, computed, inject } from '@angular/core';
import { ToastPopoverService } from './toast-popovers.service';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './toast/toast.component';

@Component({
  selector: 'app-toast-popovers',
  standalone: true,
  imports: [CommonModule, ToastComponent],
  templateUrl: './toast-popovers.component.html',
  styleUrl: './toast-popovers.component.scss',
  host: {
    class: 'absolute',
  },
})
export class ToastPopoversComponent {
  toastService = inject(ToastPopoverService);
  toasts = computed(this.toastService.toasts);
}
