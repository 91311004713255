import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-skip-content',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslocoModule],
  templateUrl: './skip-content.component.html',
  styleUrls: ['./skip-content.component.scss'],
  host: {
    class: 'full-width relative z-50',
  },
})
export class SkipContentComponent {}
